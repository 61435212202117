import CRUDService from 'common/services/crud'
import store from 'store'
import { SET_ACTIVE_VENUE, UNSET_ACTIVE_VENUE } from './store'
import { App } from 'index'


class VenueCRUDService extends CRUDService {
    constructor() {
        super('venue')
    }

    fetch = (feiId) => !feiId
        ? Promise.reject('Missing FEI ID.')
        : this.$http.post(this.url + 'fetch_from_fei/', { fei_id: feiId })

    importParticipations = (venueId) => {
        if (!venueId)
            return Promise.reject('Missing Venue ID.')

        return this.$http.post(this.getObjectURL(venueId) + 'importParticipations/')
    }

    uploadFeiParticipations = (venueId, data) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : !data
            ? Promise.reject('Missing data.')
            : this.$http.post(this.getObjectURL(venueId) + 'uploadFeiParticipations/', data)

    uploadFeiMasterlist = (venueId, data) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : !data
            ? Promise.reject('Missing data.')
            : this.$http.post(this.getObjectURL(venueId) + 'uploadFeiMasterlist/', data)


    importCompetitions = (venueId, commit = false) => {
        if (!venueId)
            return Promise.reject('Missing Venue ID.')

        return this.$http.post(this.getObjectURL(venueId) + 'importCompetitions/', { commit })
    }

    invoices = (venueId, params) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : this.$http.get(this.getObjectURL(venueId) + 'invoices/', { params })

    generateInvoices = (venueId) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : this.$http.post(this.getObjectURL(venueId) + 'generateInvoices/')

    generateInvoice = (venueId, params = {}) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : !params.venueParticipationID
            ? Promise.reject('Missing Venue participation ID.')
            : this.$http.get(this.getObjectURL(venueId) + 'generateInvoice/', { params })

    emailInvoices = (venueId) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : this.$http.post(this.getObjectURL(venueId) + 'emailInvoices/')

    getUsers = (venueId) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : this.$http.get(this.getObjectURL(venueId) + 'users/')

    saveUsers = (venueId, data) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : this.$http.post(this.getObjectURL(venueId) + 'users/', data)
            .then((response) => {
                App.notify('Saved successfully.', 'success')
                return response
            })

    whereRiderParticipated = (riderId) => !riderId
        ? Promise.reject('Missing rider ID.')
        : this.$http.post(this.url + 'whereRiderParticipated/', { rider: riderId })

    whereOfficialParticipated = (officialId) => !officialId
        ? Promise.reject('Missing official ID.')
        : this.$http.post(this.url + 'whereOfficialParticipated/', { official: officialId })

    uploadResults = (venueId, data) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : !data
            ? Promise.reject('Missing data.')
            : this.$http.post(this.getObjectURL(venueId) + 'uploadResults/', data)

    assignHeadNumbers = ({ venue }) => !venue
        ? Promise.reject('Missing Venue ID.')
        : this.$http.post(this.getObjectURL(venue) + 'assignHeadNumbers/')

    deleteHeadNumbers = ({ venue }) => !venue
        ? Promise.reject('Missing Venue ID.')
        : this.$http.post(this.getObjectURL(venue) + 'deleteHeadNumbers/')

    sendOnboardingEmailToSelf = (venueId) => !venueId
        ? Promise.reject('Missing venue ID.')
        : this.$http.post(this.getObjectURL(venueId) + 'sendOnboardingEmailToSelf/')

    downloadInvoices = (venueId) => !venueId
        ? Promise.reject('Missing venue ID.')
        : window.open(this.getObjectURL(venueId) + 'downloadInvoices/')

    generateXMLresults = (venueId) => !venueId
        ? Promise.reject('Missing venue ID.')
        : window.open(this.getObjectURL(venueId) + 'generateXMLresults/')

    exportConsumablesPDF = (venueId) => !venueId
        ? Promise.reject('Missing Venue ID.')
        : this.$http.post(this.getObjectURL(venueId) + 'exportConsumablesPDF/')


}

export default new VenueCRUDService()

// export const get = VenueCRUDService.get

export function setVenue(venue) {
    store.commit(SET_ACTIVE_VENUE, venue)
}

export function unsetVenue() {
    store.commit(UNSET_ACTIVE_VENUE)
}

class _EmailTemplateService extends CRUDService {
    constructor() {
        super('emailTemplates')
    }

    send = ({ venue, id }) => !venue || !id
        ? Promise.reject('Missing ID or venue ID.')
        : this.$http.post(this.getObjectURL(id) + 'send/', { venue })
}

export const EmailTemplateService = new _EmailTemplateService()
